<template>
  <sidenav :orientation="orientation" :class="curClasses" class="bg-danger">

    <!-- Brand demo (see src/demo.css) -->
    <div class="app-brand demo" v-if="orientation !== 'horizontal'">
      <span class="app-brand-logo demo bg-danger"></span>
      <router-link to="/" class="app-brand-text demo sidenav-text font-weight-normal ml-2">ALMANARA</router-link>
      <a href="javascript:void(0)" class="layout-sidenav-toggle sidenav-link text-large ml-auto" @click="toggleSidenav()">
        <i class="ion ion-md-menu align-middle"></i>
      </a>
    </div>
    <div class="sidenav-divider mt-0" v-if="orientation !== 'horizontal'"></div>

    <!-- Links -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <!-- Customer -->
      <sidenav-router-link icon="ion ion-md-people" to="/customers" :exact="true">Customers</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-people" to="/brokers" :exact="true">Customs Broker</sidenav-router-link>
      <sidenav-router-link icon="ion ion-md-paper" to="/cars/arrived" :exact="true">Arrived</sidenav-router-link>
      <sidenav-router-link icon="fas fa-file-archive" to="/cars/archive" :exact="true">Archive</sidenav-router-link>
      <!-- Containers -->
      <sidenav-menu  icon="fas fa-truck-loading"  :active="isMenuActive('/layouts')" :open="isMenuOpen('/layouts')">
      <template slot="link-text">Containers</template>
        <sidenav-router-link to="/containers/arrived" :exact="true">Arrvied Containers</sidenav-router-link>
        <sidenav-router-link to="/containers/archive" :exact="true">Archive Containers</sidenav-router-link>
      </sidenav-menu>

       <sidenav-menu  icon="fas fa-truck-loading"  :active="isMenuActive('/layouts')" :open="isMenuOpen('/layouts')">
      <template slot="link-text">Shipping Containers</template>
        <!-- <sidenav-router-link to="/containers/shipping/transit" :exact="true">Transit Containers</sidenav-router-link> -->
        <sidenav-router-link to="/containers/shipping/arrived" :exact="true">Arrvied Containers</sidenav-router-link>
        <sidenav-router-link to="/containers/shipping/archive" :exact="true">Archive Containers</sidenav-router-link>
      </sidenav-menu>

      <sidenav-menu icon="far fa-money-bill-alt" :active="isMenuActive('/layouts')" :open="isMenuOpen('/layouts')">
      <template slot="link-text">Storage Fees</template>
        <sidenav-router-link to="/storagefees/customers" :exact="true">Customers</sidenav-router-link>
        <sidenav-router-link to="/storagefees/brokers" :exact="true">Brokers</sidenav-router-link>
      </sidenav-menu>
    </div>
  </sidenav>
</template>

<script>
// import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
import { Sidenav, SidenavRouterLink, SidenavMenu } from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    Sidenav,
    // SidenavLink,
    SidenavRouterLink,
    SidenavMenu
    // SidenavHeader,
    // SidenavBlock,
    // SidenavDivider
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
