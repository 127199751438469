import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  path: '/customers',
  component: LayoutHorizontalSidenav,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/customers/Customers'),
      meta: {
        keepAlive: true
      }
    }
    // {
    //   path: 'create',
    //   component: () => import('@/components/customers/CreateCustomer'),
    //   name: 'createCustomer'
    // },
    // {
    //   path: 'edit/:id',
    //   component: () => import('@/components/customers/EditCustomer'),
    //   name: 'editCustomer',
    //   props: true,
    //   meta: {
    //     keepAlive: false
    //   }
    // }
  ]
}]
