import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  path: '/storagefees',
  component: LayoutHorizontalSidenav,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'customers',
      component: () => import('@/components/storagefees/StorageFees')
    },
    {
      path: 'brokers',
      component: () => import('@/components/storagefees/StorageFeesBroker')
    },
    {
      path: 'details/:id',
      component: () => import('@/components/storagefees/StorageFeesDetails'),
      props: true
    },
    {
      path: 'broker/details/:id',
      component: () => import('@/components/storagefees/StorageFeesDetailsBroker'),
      props: true
    },
    {
      path: 'container/details/:id',
      component: () => import('@/components/storagefees/StorageFeesContainerDetails'),
      props: true
    }
  ]
}]
