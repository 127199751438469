import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store/store";
import axios from "axios";
import BootstrapVue from "bootstrap-vue";
import globals from "./globals";
import Popper from "popper.js";
import Echo from "laravel-echo";

Vue.prototype.$http = axios;

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

// Global RTL flag
Vue.mixin({
  data: globals,
});

window.Pusher = require("pusher-js");

window.Echo = new Echo({
  authEndpoint: "https://agency-api.almanara-shipping.com/broadcasting/auth",
  broadcaster: "pusher",
  key: "08d2caab7560d1b5f1ea",
  cluster: "eu",
  encrypted: true,
  // forceTLS: true,
  auth: {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  },
});

axios.defaults.headers.common = {
  // 'X-CSRF-TOKEN': Laravel.csrfToken,
  "X-Requested-With": "XMLHttpRequest",
  Authorization: "Bearer " + localStorage.getItem("token"),
};

axios.defaults.baseURL = "http://agency-api.almanara-shipping.com/";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
