import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  path: '/brokers',
  component: LayoutHorizontalSidenav,
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      component: () => import('@/components/customsBrokers/CustomsBrokers')
    },
    {
      path: 'create',
      component: () => import('@/components/customsBrokers/CreateCustomsBroker'),
      name: 'createCustomsBroker'
    },
    {
      path: 'edit/:id',
      component: () => import('@/components/customsBrokers/EditCustomsBroker'),
      name: 'editCustomsBroker',
      props: true
    }
  ]
}]
