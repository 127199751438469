<template>
<div>
  <b-navbar toggleable="lg" :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x navbar-expand-lg bg-dark">
    <!-- Brand demo (see demo.css) -->
    <b-navbar-brand to="/dashboard" class="app-brand demo d-lg-none py-0 mr-4">
      <!-- <span class="app-brand-logo demo bg-primary">
        <svg viewBox="0 0 148 80" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><linearGradient id="a" x1="46.49" x2="62.46" y1="53.39" y2="48.2" gradientUnits="userSpaceOnUse"><stop stop-opacity=".25" offset="0"></stop><stop stop-opacity=".1" offset=".3"></stop><stop stop-opacity="0" offset=".9"></stop></linearGradient><linearGradient id="e" x1="76.9" x2="92.64" y1="26.38" y2="31.49" xlink:href="#a"></linearGradient><linearGradient id="d" x1="107.12" x2="122.74" y1="53.41" y2="48.33" xlink:href="#a"></linearGradient></defs><path style="fill: #fff;" transform="translate(-.1)" d="M121.36,0,104.42,45.08,88.71,3.28A5.09,5.09,0,0,0,83.93,0H64.27A5.09,5.09,0,0,0,59.5,3.28L43.79,45.08,26.85,0H.1L29.43,76.74A5.09,5.09,0,0,0,34.19,80H53.39a5.09,5.09,0,0,0,4.77-3.26L74.1,35l16,41.74A5.09,5.09,0,0,0,94.82,80h18.95a5.09,5.09,0,0,0,4.76-3.24L148.1,0Z"></path><path transform="translate(-.1)" d="M52.19,22.73l-8.4,22.35L56.51,78.94a5,5,0,0,0,1.64-2.19l7.34-19.2Z" fill="url(#a)"></path><path transform="translate(-.1)" d="M95.73,22l-7-18.69a5,5,0,0,0-1.64-2.21L74.1,35l8.33,21.79Z" fill="url(#e)"></path><path transform="translate(-.1)" d="M112.73,23l-8.31,22.12,12.66,33.7a5,5,0,0,0,1.45-2l7.3-18.93Z" fill="url(#d)"></path></svg>
      </span> -->
      <span class="app-brand-text demo font-weight-normal ml-2">ALMANARA</span>
    </b-navbar-brand>

    <!-- Sidenav toggle (see demo.css) -->
    <b-navbar-nav class="layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto" v-if="sidenavToggle">
      <a class="nav-item nav-link px-0 mr-lg-4" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse is-nav id="app-layout-navbar">
      <!-- Divider -->
      <hr class="d-lg-none w-100 my-2">

      <b-navbar-nav class="align-items-lg-center">
        <!-- Search -->
        <!-- <label class="nav-item navbar-text navbar-search-box p-0 active">
          <i class="ion ion-ios-search navbar-icon align-middle"></i>
          <span class="navbar-search-input pl-2">
            <input id="search-bar" type="text" class="form-control navbar-text mx-2" placeholder="Search..."
             style="width:200px;" v-model="keywords" @keyup.enter="generalSearch">
          </span>
        </label> -->
      </b-navbar-nav>

      <b-navbar-nav class="align-items-lg-center ml-auto">

        <!-- Divider -->
        <div class="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1">|</div>

        <b-nav-item-dropdown :right="!isRTL" class="demo-navbar-user">
          <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <img v-if="this.$store.state.user.avatar === null" :src="`${publicUrl}img/avatars/1.png`" alt class="d-block ui-w-30 rounded-circle">
              <img v-else :src="this.$store.state.user.avatar" alt class="d-block ui-w-30 rounded-circle">

              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{ this.$store.state.user.name}}</span>
            </span>
          </template>

          <!-- <b-dd-item><i class="ion ion-ios-person text-lightest"></i> &nbsp; My profile</b-dd-item>
          <b-dd-item><i class="ion ion-ios-mail text-lightest"></i> &nbsp; Messages</b-dd-item> -->
          <b-dd-item to="/account-settings"><i class="ion ion-md-settings text-lightest"></i> &nbsp; Account settings</b-dd-item>
          <b-dd-divider />
          <b-dd-item :to="{name:'Logout'}"><i class="ion ion-ios-log-out text-danger"></i> &nbsp; Log Out</b-dd-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

  <!-- @detected-condition fires when the connectivity status of the device changes -->
  <!-- <offline @detected-condition="handleConnectivityChange">

    <div slot="online">
      <p>It looks like you're online! Here's all the things you can do...</p>
    </div>

    <div slot="offline">
      <p>You appear to be offline, that's okay, we can still do things...</p>
    </div>
  </offline> -->
    <!-- vue-notification -->
      <offline @detected-condition="handleConnectivityChange" style="display:none">
        <!-- <div slot="online">
          <p>It looks like you're online! Here's all the things you can do...</p>
        </div> -->

        <div slot="offline">
          <p>You appear to be offline, that's okay, we can still do things...</p>
        </div>
      </offline>

    <!-- vue-notification -->

</div>
</template>

<style src="@/vendor/libs/vue-toasted/vue-toasted.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>

<!-- Custom template animation -->
<style>
  .v-fade-left-enter-active,
  .v-fade-left-leave-active,
  .v-fade-left-move {
    transition: all .5s;
  }
  .v-fade-left-enter,
  .v-fade-left-leave-to {
    opacity: 0;
    transform: translateX(-500px) scale(0.2);
  }

.badge.indicator:not(.badge-dot) {
    padding: .3em .4em;
    font-size: .6em;
    line-height: 1.2em;
    -webkit-transform: translate(-50%, -10%);
    transform: translate(-50%, -10%);
}
#search-bar::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #fff !important;
}
#search-bar::-moz-placeholder { /* Firefox 19+ */
  color: #fff !important;
}
#search-bar:-ms-input-placeholder { /* IE 10+ */
  color: #fff !important;
}
#search-bar:-moz-placeholder { /* Firefox 18- */
  color: #fff !important;
}

</style>

<script>
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import axios from 'axios'
import offline from 'v-offline'
import Vue from 'vue'
import Toasted from 'vue-toasted'
import Notifications from 'vue-notification'
Vue.use(Toasted)
Vue.use(Notifications)

export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  components: {
    offline
  },

  data () {
    return {
      userId: null,
      profile: {},
      unreadCount: 0,
      unreadNotifications: [],
      newNotification: {
        data: {
          subject: null,
          content: null
        },
        created_at: null,
        updated_at: null,
        read_at: null
      },
      moment: moment,
      toastText: '',
      toastActions: false,

      toastStyle: 'outline',
      toastStyles: [
        { text: 'Default', value: 'primary' },
        { text: 'Bubble', value: 'bubble' },
        { text: 'Outline', value: 'outline' }
      ],

      toastVerticalPosition: 'top',
      toastVerticalPositions: [
        { text: 'Top', value: 'top' },
        { text: 'Bottom', value: 'bottom' }
      ],
      toastHorizontalPosition: 'center',
      toastHorizontalPositions: [
        { text: 'Left', value: 'left' },
        { text: 'Center', value: 'center' },
        { text: 'Right', value: 'right' }
      ],

      toastVariant: 'success',
      toastVariants: [
        { text: 'None', value: null },
        { text: 'Primary', value: 'primary' },
        { text: 'Secondary', value: 'secondary' },
        { text: 'Success', value: 'success' },
        { text: 'Warning', value: 'warning' },
        { text: 'Info', value: 'info' },
        { text: 'Danger', value: 'danger' },
        { text: 'Dark', value: 'dark' }
      ],
      keywords: ''
    }
  },

  computed: {
    notificationToDisplay () {
      return this.unreadNotifications.slice(0, 5)
    }
  },

  mounted () {
    this.getProfile()
  },

  watch: {
    // call again the method if the route changes
    '$route': 'getProfile'
  },

  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },

    // Listen
    listen () {
      if (localStorage.getItem('token')) {
        let decoded = jwtDecode(localStorage.getItem('token'))
        window.Echo.private('App.Models.User.' + decoded.sub)
          .notification((notification) => {
            this.newNotification.data.subject = notification.subject
            this.newNotification.data.content = notification.content
            this.newNotification.created_at = notification.created_at.date
            this.newNotification.updated_at = notification.updated_at.date
            this.toastText = this.newNotification.data.content
            this.showToast()
            this.unreadNotifications.unshift(this.newNotification)
            this.$store.dispatch('getDashboardData')
          })

        window.Echo.join('chat-room.' + 1)
          .joining((user) => {
            axios.post('/api/userOnline/' + user.id, {})
          })
          .leaving((user) => {
            axios.post('/api/userOnline/' + user.id, {})
          })
      }
    },

    getProfile () {
      axios({
        method: 'get',
        url: '/api/profile'
      }).then(res => {
        const user = res.data.profile
        this.$store.commit('authSuccess', { user })
        this.profile = res.data.profile
        this.userId = res.data.profile.id
      })
    },

    getNotification () {
      axios({
        method: 'get',
        url: '/api/notifications'
      }).then(res => {
        this.unreadNotifications = res.data.unreadNotifications
        this.unreadCount = this.unreadNotifications.length
      })
    },

    showAllNotification () {
      axios({
        method: 'post',
        url: '/api/notifications/makeRead'
      }).then(res => {
        this.getNotification()
        this.$router.push({ name: 'Notifications' })
      })
    },

    getCustomClasses () {
      if (!this.toastVariant) return null

      if (this.toastStyle !== 'outline') {
        return `bg-${this.toastVariant} text-${this.toastVariant === 'warning' ? 'dark' : 'white'}`
      } else {
        return `border-${this.toastVariant} text-${this.toastVariant}`
      }
    },
    getActionCustomClasses () {
      if (!this.toastVariant) return null

      if (this.toastStyle !== 'outline') {
        return `opacity-75 text-${this.toastVariant === 'warning' ? 'dark' : 'white'}`
      } else {
        return `opacity-75 text-${this.toastVariant}`
      }
    },

    showToast () {
      const actions = [{
        text: 'Cancel',
        onClick: (e, toastObject) => {
          toastObject.goAway(0)
        },
        class: this.getActionCustomClasses()
      }, {
        text: 'Undo',
        push: {
          name: 'somewhere',
          dontClose: true
        },
        class: this.getActionCustomClasses()
      }]

      this.$toasted.show(this.toastText, {
        theme: this.toastStyle,
        position: `${this.toastVerticalPosition}-${this.toastHorizontalPosition}`,
        action: this.toastActions ? actions : null,
        icon: this.toastIcon || null,
        className: this.getCustomClasses(),
        duration: 4000
      })
    },

    generalSearch () {
      var keywords = this.keywords
      this.$store.state.jsonData = []
      this.$store.state.originalJsonData = []
      this.$store.dispatch('searchVehicleAction', keywords)
      this.$router.push({ name: 'SearchResult' })
    },

    // Check internet connection
    handleConnectivityChange (status) {
      var message = ''
      if (status) {
        message = 'It looks like you are online! Here is all the things you can do...'
        this.toastVariant = 'success'
      } else {
        message = 'No internet connection.'
        this.toastVariant = 'danger'
      }
      this.toastText = message
      this.showToast()
    }
  }
}
</script>
