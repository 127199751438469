import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  path: '/containers',
  component: LayoutHorizontalSidenav,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'transit',
      component: () => import('@/components/containers/Containers.vue')
    },
    {
      path: 'arrived',
      component: () => import('@/components/containers/ArrivedContainers.vue'),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'archive',
      component: () => import('@/components/containers/ArchiveContainers.vue')
    },
    {
      path: 'showContainer/:id',
      component: () => import('@/components/containers/ShowContainer.vue'),
      name: 'ShowContainer',
      props: true
    },
    {
      path: 'shipping/transit',
      component: () => import('@/components/containers/shipping/ArchiveContainers.vue')
    },
    {
      path: 'shipping/arrived',
      component: () => import('@/components/containers/shipping/ArrivedContainers.vue')
    },
    {
      path: 'shipping/archive',
      component: () => import('@/components/containers/shipping/ArchiveContainers.vue')
    }
  ]
}]
