import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'

// import dashboardsRoutes from './dashboards'
import layoutsRoutes from './layouts'
import customersRoutes from './customers'
import brokerRoutes from './brokers'
import carsRoutes from './cars'
// import carAlbumPhotos from './photos'
import profileRoutes from './profile'
// import notificationRoutes from './notification'
// import accountRoutes from './account'
// import InvoiceRoutes from './invoice'
// import RequestRoutes from './request'
// import MessageRoutes from './message'
import ContainerRoutes from './containers'
import StorageFeeRoutes from './storagefees'

// Authentication Router
import authRouter from './auth'
// Vuex Store
import store from '@/store/store'

Vue.use(Router)
Vue.use(Meta)

const ROUTES = [
  // Default route
  { path: '', redirect: '/cars/arrived' }
]
  // .concat(dashboardsRoutes)
  .concat(layoutsRoutes)
  .concat(customersRoutes)
  .concat(brokerRoutes)
  .concat(carsRoutes)
  // .concat(carAlbumPhotos)
  .concat(authRouter)
  .concat(profileRoutes)
  // .concat(notificationRoutes)
  // .concat(accountRoutes)
  // .concat(InvoiceRoutes)
  // .concat(RequestRoutes)
  // .concat(MessageRoutes)
  .concat(ContainerRoutes)
  .concat(StorageFeeRoutes)

// const router = new Router({
//   //base: '/',
//   // base: process.env.MIX_PUBLIC_PATH,
//   base: process.env.MIX_ROUTER_BASE,
//   mode: 'history',
//   routes: ROUTES
// })

const router = new Router({
  base: 'ap/',
  mode: 'history',
  routes: ROUTES
})

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }

  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')
  // check if the route requires authentication and user is not logged in
  if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isAuthenticated) {
    next({ name: 'Login' })
    return
  }
  // if logged in redirect to dashboard
  if (to.path === '/auth/login' && store.state.isAuthenticated) {
    next({ path: '/dashboard' })
    return
  }
  next()
  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
