import LayoutBlank from '@/layout/LayoutBlank'

export default [{
  path: '/auth',
  component: LayoutBlank,
  children: [
    {
      path: 'login',
      component: () => import('@/components/auth/Login'),
      name: 'Login'
    },
    {
      path: 'logout',
      component: () => import('@/components/auth/Logout'),
      name: 'Logout',
      meta: { requiresAuth: true }
    },
    {
      path: 'password/forgot',
      component: () => import('@/components/auth/EmailPassword'),
      name: 'ForgotPassword'
    },
    {
      path: 'password/reset/:token',
      component: () => import('@/components/auth/ResetPassword'),
      name: 'ResetPassword',
      props: true
    }
  ]
}]
