import LayoutHorizontalSidenav from '@/layout/LayoutHorizontalSidenav'

export default [{
  path: '/cars',
  component: LayoutHorizontalSidenav,
  meta: {
    requiresAuth: true,
    keepAlive: true
  },
  children: [
    // {
    //   path: 'inventory',
    //   component: () => import('@/components/cars/Inventory.vue'),
    //   meta: {
    //     keepAlive: true
    //   }
    // },
    // {
    //   path: 'process',
    //   component: () => import('@/components/cars/Process.vue'),
    //   meta: {
    //     keepAlive: true
    //   }
    // },
    // {
    //   path: 'transit',
    //   component: () => import('@/components/cars/Transit.vue'),
    //   meta: {
    //     keepAlive: true
    //   }
    // },
    {
      path: 'arrived',
      component: () => import('@/components/cars/Arrived.vue'),
      meta: {
        keepAlive: true
      }
    },
    {
      path: 'archive',
      component: () => import('@/components/cars/Archive.vue'),
      meta: {
        keepAlive: true
      }
    },
    // {
    //   path: 'issues',
    //   component: () => import('@/components/cars/TitleIssues.vue'),
    //   meta: {
    //     keepAlive: true
    //   }
    // },
    // {
    //   path: 'create',
    //   component: () => import('@/components/cars/crud/CreateCar.vue'),
    //   name: 'createCar'
    // },
    // {
    //   path: 'edit/:id',
    //   component: () => import('@/components/cars/crud/EditCar.vue'),
    //   name: 'editCar',
    //   props: true,
    //   meta: {
    //     keepAlive: false
    //   }
    // },
    {
      path: 'searchResult',
      component: () => import('@/components/cars/SearchResult.vue'),
      name: 'SearchResult',
      props: true,
      meta: {
        keepAlive: true
      }
    }
  ]
}]
